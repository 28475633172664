import React, { useEffect, useState, useCallback } from "react";
import {
  Grid,
  Checkbox,
  FormControlLabel,
  Box,
  makeStyles,
  useTheme,
  Paper,
} from "@material-ui/core";
import { FormikProps } from "formik";
import ScheduleInfo from "@components/ScheduleInfo";
import TruckInteractive from "@components/TruckInteractive";
import FloatingMenuFixedMobile from "@components/FloatingMenuFixedMobile";
import { useProgrammingTransport } from "@context/ProgrammingTransport/ProgrammingTransportContext";
import { formatDateAndHourPTBR } from "@utils/index";
import { useHistory } from "react-router-dom";
import ROUTES from "@config/routes";
import { FormHelperText, useMediaQuery, Typography } from "@mui/material";
import GroupMenuBackAndNext from "@components/GroupMenuBackAndNext";
import AppError from "@utils/AppError";
import useDialogAlert from "@hooks/useDialogAlert";

export interface Compartment {
  capacity: number;
  index: number;
  type: string;
  isChecked: boolean;
}

interface StepOneFormValues {
  truckPlate: string;
  truckCompartmentOccupied: boolean;
}

interface InnerFormProps {
  formikProps: FormikProps<StepOneFormValues>;
  occupiedCompartments: Compartment[];
  setOccupiedCompartments: React.Dispatch<React.SetStateAction<Compartment[]>>;
}

const InnerForm: React.FC<InnerFormProps> = ({
  formikProps,
  occupiedCompartments,
  setOccupiedCompartments,
}) => {
  const { values, setFieldValue } = formikProps;
  const [loading, setLoading] = useState(false);
  const [forceOpenModal, setForceOpenModal] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const { snackbar } = useDialogAlert();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const history = useHistory();
  const {
    getVehicle,
    compartments,
    setCompartments,
    rowSelected,
    setCreateTransportBody,
    resetContext,
  } = useProgrammingTransport();

  const notRow = !rowSelected;

  const normalizePlate = useCallback((plate: string) => {
    return plate.replace(/-/g, "").trim();
  }, []);

  useEffect(() => {
    if (!rowSelected?.plate) {
      //NOTE: Se não há placa em rowSelected, limpa e sai
      setCompartments([]);
      setFieldValue("truckPlate", "");
      return;
    }
    if (values.truckPlate === rowSelected.plate) {
      return;
    }
    const plateNormalized = normalizePlate(rowSelected.plate);
    setFieldValue("truckPlate", rowSelected.plate, false);

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getVehicle(
          rowSelected?.scheduleGroupID ?? "",
          plateNormalized
        );
        const vehicleData = response.find(
          (vehicle) => normalizePlate(vehicle.plate) === plateNormalized
        );

        if (vehicleData?.compartments?.length) {
          const fetchedCompartments = vehicleData.compartments.map((comp) => ({
            ...comp,
            isChecked: false,
          }));
          setCompartments(fetchedCompartments);
        } else {
          setCompartments([]);
        }
        setCreateTransportBody((prev) => ({
          ...prev,
          plate: plateNormalized,
        }));
      } catch (error) {
        setCompartments([]);
        if (error instanceof AppError) {
          snackbar({
            message: `${error.message}`,
            variant: "error",
          });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelected?.plate, rowSelected?.scheduleGroupID]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setFieldValue("truckCompartmentOccupied", isChecked);
    if (!isChecked) {
      setOccupiedCompartments([]);
      setForceOpenModal(false);
    } else {
      setForceOpenModal(true);
    }
  };

  const isAdvanceDisabled =
    !rowSelected?.plate ||
    (values.truckCompartmentOccupied && occupiedCompartments.length === 0);

  const hasOccupiedCompartment =
    values.truckCompartmentOccupied && compartments.length > 0;

  const handleCancel = () => {
    resetContext();
    history.push(ROUTES.USER_ROUTES.ROADLOAD);
  };

  //NOTE:
  /* Se a plate existir em rowSelected, vamos mostrá-la;
     senão, usa "-" como placeholder.*/
  const formattedPlate = rowSelected?.plate ? rowSelected.plate : "-";

  return (
    <>
      <Grid container spacing={2}>
        {isMobile && (
          <Grid item xs={12}>
            <ScheduleInfo
              agendamento={rowSelected?.Ref || ""}
              dataAgendada={formatDateAndHourPTBR(
                rowSelected?.scheduleDate,
                rowSelected?.timezone
              )}
            />
          </Grid>
        )}

        <Grid item xs={12} md={12} lg={6}>
          <Grid item>
            <Paper
              style={{
                padding: "1rem",
                borderRadius: "0.5rem",
                backgroundColor: isMobile
                  ? theme.palette.shadesOfDark.white
                  : theme.palette.shadesOfDark.background,
              }}
              elevation={0}
            >
              <Typography
                style={{
                  fontWeight: 700,
                  fontSize: isMobile ? "1.2rem" : "1.5rem",
                  marginBottom: "0.2rem",
                }}
              >
                Placa do caminhão
              </Typography>
              <Typography
                style={{
                  fontSize: isMobile ? "1.2rem" : "1.5rem",
                  color: theme.palette.primary.highlightedplus,
                }}
              >
                {formattedPlate}
              </Typography>
            </Paper>
            {loading && (
              <FormHelperText>Carregando dados do veículo...</FormHelperText>
            )}
            {notRow && (
              <FormHelperText error sx={{ fontSize: "1.1rem" }}>
                Escolha um carregamento na tabela anterior para habilitar.
              </FormHelperText>
            )}
            {!rowSelected?.plate && (
              <FormHelperText error sx={{ fontSize: "1.1rem" }}>
                Não há nenhuma placa registrada nesse agendamento.
              </FormHelperText>
            )}
          </Grid>

          {compartments.length > 0 && (
            <Grid item xs={12}>
              <FormControlLabel
                classes={{ label: classes.formControlLabel }}
                control={
                  <Checkbox
                    checked={values.truckCompartmentOccupied}
                    onChange={handleCheckboxChange}
                    style={{ color: theme.palette.primary.main }}
                  />
                }
                label="O caminhão possui compartimento ocupado."
              />
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <Grid
            xs={12}
            style={{
              display: "flex",
              minHeight: "35vh",
              alignItems: "center",
              borderRadius: "4px",
              backgroundColor:
                hasOccupiedCompartment || isMobile
                  ? theme.palette.shadesOfDark.background
                  : theme.palette.shadesOfDark.white,
            }}
          >
            {hasOccupiedCompartment && (
              <Box display="block" width="100%" textAlign="center">
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "1.8rem",
                  }}
                >
                  <TruckInteractive
                    step={1}
                    compartments={compartments}
                    filledCompartments={occupiedCompartments.map(
                      (comp) => comp.index
                    )}
                    occupiedCompartments={occupiedCompartments.map(
                      (comp) => comp.index
                    )}
                    onFilledCompartmentsChange={(filled) => {
                      const updated = compartments.filter((comp) =>
                        filled.includes(comp.index)
                      );
                      setOccupiedCompartments(updated);
                    }}
                    forceOpenModal={forceOpenModal}
                    onModalDidOpen={() => setForceOpenModal(false)}
                  />
                </Grid>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>

      {isMobile ? (
        <FloatingMenuFixedMobile
          firstButtonDescription="Cancelar"
          secondButtonDescription="Avançar"
          disableSecondButton={isAdvanceDisabled}
          handleApprove={() => formikProps.handleSubmit()}
          handleClose={handleCancel}
        />
      ) : (
        <Grid container style={{ paddingTop: "30px" }}>
          <GroupMenuBackAndNext
            firstButtonDescription="Cancelar"
            secondButtonDescription="Avançar"
            disableSecondButton={isAdvanceDisabled}
            handleApprove={() => formikProps.handleSubmit()}
            handleClose={handleCancel}
          />
        </Grid>
      )}
    </>
  );
};

export default InnerForm;

const useStyles = makeStyles({
  formControlLabel: {
    fontSize: "10pt",
  },
});
