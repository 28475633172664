import { CloseIcon, WarnIcon } from "@components/Icons";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Modal,
  Theme,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { createStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { listSegmentsDescription } from "../ListSegmentsBlock";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      borderRadius: 8,
      padding: theme.spacing(4),
      outline: "none",
      display: "flex",
      flexDirection: "column",
      width: 480,
      [theme.breakpoints.down("xs")]: {
        width: "80%",
      },
      maxHeight: "95vh",
      overflowY: "auto",
    },
    icon: {
      fontSize: "3rem",
      color: theme.palette.error.main,
    },
    columnCheckBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginTop: "2rem",
    },
    actionButton: {
      marginTop: theme.spacing(2),
      color: theme.palette.common.white,
      fontWeight: 700,
      textTransform: "none",
      borderRadius: 0,
      marginLeft: "2.4rem",
      "&.block": {
        backgroundColor: theme.palette.primary.main,
      },
      "&.unblock": {
        backgroundColor: theme.palette.success.main,
        "&:hover": {
          backgroundColor: theme.palette.success.dark,
        },
      },
      "&.Mui-disabled": {
        backgroundColor: theme.palette.grey[400],
        color: theme.palette.text.disabled,
      },
    },
    cancelButton: {
      marginTop: theme.spacing(2),
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      textTransform: "none",
    },
    titleModal: {
      color: theme.palette.text.primary,
      fontWeight: 700,
      fontSize: "2.4rem",
      marginTop: "1rem",
    },
    subTitle: {
      color: theme.palette.text.primary,
      marginTop: "2.4rem",
    },
    description: {
      color: theme.palette.text.primary,
      opacity: 0.5,
    },
    boxDescription: {
      alignItems: "center",
      textAlign: "center",
    },
    smallCheckbox: {
      "& svg": {
        fontSize: 16,
      },
    },
    label: {
      fontSize: "14px",
    },
  })
);

const ProductBlockModal = ({ open, onClose, productInfo, onConfirm }) => {
  const classes = useStyles();
  const isBlocked = productInfo.isBlocked;
  const segmentsSelected = productInfo.segmentsSelected;
  const segmentsAll = productInfo.segmentsAll;

  const [blockingCount, setBlockingCount] = useState(0);
  const [unblockingCount, setUnblockingCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [checkedSegments, setCheckedSegments] = useState<
    Record<string, boolean>
  >({});

  useEffect(() => {
    if (segmentsAll) {
      const initialCheckedSegments: Record<string, boolean> = {};

      segmentsAll.forEach((segment) => {
        initialCheckedSegments[segment.id] = false;
      });

      segmentsAll.forEach((segment) => {
        if (segmentsSelected.includes(segment.id)) {
          initialCheckedSegments[segment.id] = true;
        }
      });

      setCheckedSegments(initialCheckedSegments);
    }
  }, [segmentsAll, segmentsSelected]);

  useEffect(() => {
    if (segmentsAll) {
      const newlyBlocked = Object.keys(checkedSegments).filter(
        (key) => checkedSegments[key] && !segmentsSelected.includes(key)
      ).length;

      const newlyUnblocked = Object.keys(checkedSegments).filter(
        (key) => !checkedSegments[key] && segmentsSelected.includes(key)
      ).length;

      setBlockingCount(newlyBlocked);
      setUnblockingCount(newlyUnblocked);
    }
  }, [checkedSegments, segmentsAll, segmentsSelected]);

  const noSegmentsBlocked = segmentsSelected.length === 0;
  const actionText =
    blockingCount > 0 || noSegmentsBlocked ? "Bloquear" : "Desbloquear";

  const handleCheckboxClick = (segment) => {
    if (!isLoading) {
      setCheckedSegments((prevState) => ({
        ...prevState,
        [segment]: !prevState[segment],
      }));
    }
  };

  const handleConfirmClick = async () => {
    setIsLoading(true);
    try {
      await onConfirm(
        productInfo.branchIndex,
        productInfo.productIndex,
        isBlocked,
        checkedSegments
      );
      setIsLoading(false);
      onClose();
    } catch {
      setIsLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={!isLoading ? onClose : undefined}>
      <Box className={classes.modal}>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            padding: "1.6rem",
            margin: "1.6rem",
          }}
        >
          <CloseIcon
            onClick={!isLoading ? onClose : undefined}
            style={{ cursor: isLoading ? "default" : "pointer" }}
          />
        </Box>

        <Box className={classes.boxDescription}>
          <WarnIcon
            style={{
              fontSize: "8rem",
              marginTop: "1.5rem",
              marginBottom: "1.5rem",
            }}
          />
          <Typography className={classes.titleModal}>
            {blockingCount > 0 && unblockingCount > 0
              ? `Deseja atualizar os bloqueios de ${productInfo.product}?`
              : actionText === "Bloquear"
              ? `Deseja bloquear ${productInfo.product}?`
              : `Deseja desbloquear ${productInfo.product}?`}
          </Typography>

          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.description}
          >
            {blockingCount > 0 && unblockingCount > 0
              ? `Você está bloqueando ${blockingCount} segmentos e desbloqueando ${unblockingCount} segmentos.`
              : blockingCount > 0
              ? `Você está bloqueando ${blockingCount} segmentos.`
              : unblockingCount > 0
              ? `Você está desbloqueando ${unblockingCount} segmentos.`
              : `Nenhuma alteração foi feita. O botão permanecerá desabilitado até que você modifique os bloqueios.`}
          </Typography>

          <Typography variant="subtitle1" className={classes.subTitle}>
            <span style={{ fontWeight: 600 }}>{productInfo.product}</span>
            <span style={{ opacity: 0.5 }}> para afilial de </span>
            <span style={{ fontWeight: 600 }}>{productInfo.branch}</span>
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.description}
          >
            Essa ação poderá ser desfeita posteriormente.
          </Typography>
        </Box>

        <Box className={classes.columnCheckBox}>
          {segmentsAll.map((segment) => (
            <FormControlLabel
              key={segment.id}
              control={
                <Checkbox
                  className={classes.smallCheckbox}
                  style={{ color: "#D91F05" }}
                  color="primary"
                  value={segment.desc}
                  onChange={() => handleCheckboxClick(segment.id)}
                  checked={checkedSegments[segment.id] || false}
                  disabled={isLoading}
                />
              }
              label={listSegmentsDescription[segment.id]}
              classes={{ label: classes.label }}
            />
          ))}
        </Box>

        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "2.4rem",
            justifyContent: "center",
          }}
        >
          <Button
            variant="text"
            className={classes.cancelButton}
            onClick={!isLoading ? onClose : undefined}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            disabled={
              isLoading || (blockingCount === 0 && unblockingCount === 0)
            }
            className={`${classes.actionButton} ${
              isBlocked ? "unblock" : "block"
            } ${actionText.toLowerCase()}`}
            onClick={handleConfirmClick}
          >
            {isLoading ? (
              <CircularProgress size={24} style={{ color: "white" }} />
            ) : (
              actionText
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ProductBlockModal;
