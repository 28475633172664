import { createTheme } from "@material-ui/core/styles";

function hexToRGBWithAlpha(hex: string, alpha: number | string) {
  if (hex.length !== 7) {
    throw new Error("A cor deve possuir pelo menos 7 caracaters. Ex: #XXXXXX");
  }
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    return `rgb(${r}, ${g}, ${b})`;
  }
}

export default createTheme({
  spacing: 10,
  typography: {
    fontFamily: "Montserrat",
    htmlFontSize: 10,
  },
  palette: {
    primary: {
      main: "#D91F05",
      light: "#626166",
      highlighted: "#FFD8D8",
      highlightedplus: "#3E3D3D",
    },
    primaryLight: {
      main: "#fff5f7",
    },
    secondary: {
      main: "#FFA722",
      light: "#FFB800",
      highlighted: "#f4d995",
    },
    error: {
      main: "#F97A91",
      light: "#C30C0C",
    },
    success: {
      main: "#5AC45F",
      light: "#0BB873",
    },
    text: {
      primary: "#3E3D3D",
      secondary: "#1E1E1E",
      disabled: "rgba(0,0,0,0.12)",
      hint: "#fff",
    },
    background: {
      default: "#F5F5F5",
      paper: "#fff",
    },
    shadesOfDark: {
      black: "#000000",
      ultraDark: "#404142",
      dark: "#58595b",
      steel: "#8C8D8F",
      medium: "#AFB0B3",
      light: "#D2D3D6",
      ultraLight: "#E8E9EB",
      background: "#F3F3F3",
      white: "#FFFFFF",
      whiteLight: "#F5F5F5",
    },
    grey: {
      "100": "#D5D1CB",
      "200": "#C8C8C8",
    },
    hexToRGBWithAlpha,
    customLightGrey: "#C4C4C4",
    customDarkGrey: "#8E8484",
    borderColor: "rgba(114, 101, 81, 0.1)",
    avatar: {
      main: "#FE5623",
      background: "#f8e1e9",
    },
  },
});

interface ShadesOfDarkPaletteOptions {
  black: string;
  ultraDark: string;
  dark: string;
  steel: string;
  medium: string;
  light: string;
  ultraLight: string;
  background: string;
  white: string;
  whiteLight: string;
}

interface CustomPalette {
  customLightGrey: string;
  customDarkGrey: string;
  borderColor: string;
  avatar: {
    main: string;
    background: string;
  };
}

interface CustomPaletteOptions {
  customLightGrey?: string;
  customDarkGrey?: string;
  borderColor?: string;
  avatar?: {
    main?: string;
    background?: string;
  };
}

declare module "@material-ui/core/styles/createPalette" {
  interface Palette extends CustomPalette {
    primaryLight: Palette["primary"];
    hexToRGBWithAlpha: (hex: string, alpha: number | string) => string;
    shadesOfDark: ShadesOfDarkPaletteOptions;
  }

  interface PaletteOptions extends CustomPaletteOptions {
    primaryLight: PaletteOptions["primary"];
    hexToRGBWithAlpha?: (hex: string, alpha: number | string) => string;
    shadesOfDark: ShadesOfDarkPaletteOptions;
  }

  interface SimplePaletteColorOptions {
    highlighted?: string;
    highlightedplus?: string;
  }

  interface PaletteColor {
    highlighted?: string;
    highlightedplus?: string;
  }
}

// NOTE: Estendendo as interfaces para adicionar as novas propriedades
declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    customLightGrey: string;
    customDarkGrey: string;
    borderColor: string;
    avatar: {
      main: string;
      background: string;
    };
  }

  interface PaletteOptions {
    customLightGrey?: string;
    customDarkGrey?: string;
    borderColor?: string;
    avatar?: {
      main?: string;
      background?: string;
    };
  }
}
