import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Typography,
  Paper,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import FloatingMenuFixedMobile from "@components/FloatingMenuFixedMobile";
import ProductList from "./ProductList";
import MessageInfo from "./MessageInfoTitle";
import {
  Product,
  useProgrammingTransport,
} from "@context/ProgrammingTransport/ProgrammingTransportContext";

import ScheduleInfo from "@components/ScheduleInfo";
import { formatDateAndHourPTBR } from "@utils/index";
import ModalFinalCreateTransport from "./ModalFinalCreateTransport";
import {
  ITransportItem,
  TransportItemType,
} from "@modules/transport/models/ICreateTransportBody";
import AppError from "@utils/AppError";
import { useSnackbar } from "notistack";
import GroupMenuBackAndNext from "@components/GroupMenuBackAndNext";
import ROUTES from "@config/routes";
import { Box } from "@mui/material";
import { AlertYellowCircleIcon } from "@components/Icons";

interface SectionThreeProps {
  onBack: () => void;
}

const ProgrammingSectionThree: React.FC<SectionThreeProps> = ({ onBack }) => {
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const { enqueueSnackbar } = useSnackbar();
  const {
    createTransportBody,
    createTransport,
    rowSelected,
    compartments,
    allAllocatedProducts,
  } = useProgrammingTransport();
  const { plate, items } = createTransportBody;
  const [isModalOpen, setModalOpen] = useState(false);
  const [programNumber] = useState("");

  const formattedPlate = plate.includes("-")
    ? plate
    : `${plate.slice(0, 3)}-${plate.slice(3)}`;

  /**
   * Aqui vamos reunir "produtos" para exibir no ProductList.
   * Ele agrupa items pela orderRef, somando volume, etc.
   */
  const products = items.reduce((acc, item: ITransportItem) => {
    if (item.type === TransportItemType.BLOCKED) {
      acc.push({
        id: `${item.slotIndex}`,
        name: "Compartimento ocupado",
        volume: item.volume,
        compartments: [item.slotIndex],
      });
      return acc;
    }

    const existing = acc.find((p) => p.id === item.orderRef);
    if (existing) {
      existing.volume += item.volume;
      existing.compartments.push(item.slotIndex);
    } else {
      acc.push({
        id: item.orderRef || "",
        name: item.productName ? `Produto ${item.productName}` : "",
        volume: item.volume,
        compartments: [item.slotIndex],
      });
    }
    return acc;
  }, [] as { id: string; name: string; volume: number; compartments: number[] }[]);

  const productsPending = allAllocatedProducts.reduce(
    (acc, product: Product) => {
      const productPending = !items.some(
        (item) => item.orderRef === product.id
      );

      if (productPending) {
        acc.push({
          id: product.id,
          name: product.name,
          volume: product.volume,
          compartments: [],
          Status: product.Status,
        });
      }

      return acc;
    },
    [] as {
      id: string;
      name: string;
      volume: number;
      compartments: number[];
      Status: string;
    }[]
  );

  const capacityText = `${compartments
    .reduce((acc, c) => acc + c.capacity, 0)
    .toLocaleString()} litros`;

  // Soma total alocado
  const allocatedVolume = items
    .filter((item) => item.orderRef)
    .reduce((sum, item) => sum + item.volume, 0);

  // Soma total pendente
  const pendingVolume = productsPending.reduce(
    (sum, item) => sum + item.volume,
    0
  );

  /**
   * Ao confirmar, removemos productName (se não quiser enviar)
   * e chamamos createTransport
   */
  const handleConfirm = async () => {
    //NOTE: Cria o payload final, removendo productName e garantindo que orderRef seja omitido para TransportItemType.BLOCKED
    const finalItems = createTransportBody.items.map((item: ITransportItem) => {
      const { productName, ...rest } = item;
      if (item.type === TransportItemType.BLOCKED) {
        const { orderRef, ...blockedItem } = rest;
        return blockedItem;
      }
      return rest;
    });

    //NOTE: Ordena os itens: primeiro TransportItemType.BLOCKED, depois TransportItemType.ALLOCATED
    const sortedItems = finalItems.sort((a, b) => {
      if (
        a.type === TransportItemType.BLOCKED &&
        b.type === TransportItemType.ALLOCATED
      )
        return -1;
      if (
        a.type === TransportItemType.ALLOCATED &&
        b.type === TransportItemType.BLOCKED
      )
        return 1;
      return 0;
    });

    const finalBody = {
      plate: createTransportBody.plate,
      items: sortedItems,
    };
    try {
      await createTransport(rowSelected?.scheduleGroupID || "", finalBody);
      setModalOpen(true);
    } catch (error) {
      if (error instanceof AppError) {
        enqueueSnackbar(error.message, {
          variant: error.variant,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
      }
    }
  };

  return (
    <Grid
      container
      style={{
        fontFamily: "Montserrat, sans-serif",
        color: theme.palette.primary.light,
        padding: "1rem",
      }}
      spacing={2}
    >
      <Grid item xs={12}>
        <MessageInfo title="Confira as informações do carregamento" />
      </Grid>

      {isMobile && (
        <Grid item xs={12}>
          <ScheduleInfo
            agendamento={rowSelected?.Ref || ""}
            dataAgendada={formatDateAndHourPTBR(
              rowSelected?.scheduleDate,
              rowSelected?.timezone
            )}
          />
        </Grid>
      )}

      <Grid item xs={12} sm={12} md={6}>
        <Paper
          style={{
            padding: "1rem",
            borderRadius: "0.5rem",
            backgroundColor: isMobile
              ? theme.palette.shadesOfDark.white
              : theme.palette.shadesOfDark.background,
          }}
          elevation={0}
        >
          <Grid container spacing={2}>
            <Grid item>
              <Typography
                style={{
                  fontWeight: 700,
                  fontSize: isMobile ? "1.2rem" : "1.5rem",
                  marginBottom: "0.2rem",
                }}
              >
                Placa do caminhão
              </Typography>
              <Typography
                style={{
                  fontSize: isMobile ? "1.2rem" : "1.5rem",
                  color: theme.palette.primary.highlightedplus,
                }}
              >
                {formattedPlate}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Typography
          style={{
            fontSize: isMobile ? "1.2rem" : "1.5rem",
            color: theme.palette.primary.highlightedplus,
            marginTop: "0.5rem",
          }}
        >
          Capacidade Total:
          <span style={{ fontWeight: 500, marginLeft: "0.5rem" }}>
            {capacityText}
          </span>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <ProductList products={products} onProductClick={() => {}} />
        <Box sx={{ height: "1rem" }} />
        <ProductList products={productsPending} onProductClick={() => {}} />

        {productsPending.length > 0 && (
          <Grid item xs={12} style={{ paddingTop: "2rem" }}>
            <Paper
              style={{
                padding: "2rem",
                borderRadius: "0.5rem",
                borderLeft: `10px solid ${theme.palette.secondary.main}`,
                backgroundColor: theme.palette.secondary.highlighted,
              }}
              elevation={0}
            >
              <Grid
                container
                spacing={2}
                style={{ justifyContent: "left", flexDirection: "row" }}
              >
                {!isMobile && (
                  <Grid item>
                    <AlertYellowCircleIcon
                      style={{
                        height: "50px",
                        width: "50px",
                      }}
                    />
                  </Grid>
                )}

                <Grid item>
                  <Typography variant="button">Atenção</Typography>
                  <Typography
                    style={{
                      fontSize: isMobile ? "1.2rem" : "1.5rem",
                      marginBottom: "0.2rem",
                      color: theme.palette.primary.highlightedplus,
                    }}
                  >
                    Há produtos pendentes que precisam ser agendados e
                    programados
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}
      </Grid>

      <Grid item xs={12}>
        <Typography
          style={{
            fontSize: isMobile ? "1.2rem" : "1.5rem",
            color: theme.palette.secondary.main,
            marginBottom: "1rem",
            fontWeight: 700,
          }}
        >
          Quantidade pendente de carregamento:
          <span style={{ fontWeight: 500, marginLeft: "0.5rem" }}>
            {`${pendingVolume.toLocaleString()} litros`}
          </span>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography
          style={{
            fontSize: isMobile ? "1.2rem" : "1.5rem",
            color: theme.palette.success.main,
            marginBottom: isMobile ? "3.6rem" : "1rem",
            fontWeight: 700,
          }}
        >
          Quantidade selecionada para carregamento:
          <span
            style={{
              fontWeight: 500,
              marginLeft: "0.5rem",
            }}
          >
            {`${allocatedVolume.toLocaleString()} litros`}
          </span>
        </Typography>
      </Grid>

      {isMobile ? (
        <Grid item xs={12}>
          <FloatingMenuFixedMobile
            firstButtonDescription="Voltar"
            secondButtonDescription="Confirmar"
            handleApprove={handleConfirm}
            handleClose={onBack}
          />
        </Grid>
      ) : (
        <Grid container style={{ margin: "1rem" }}>
          <GroupMenuBackAndNext
            firstButtonDescription="Voltar"
            secondButtonDescription="Confirmar"
            handleApprove={() => {
              handleConfirm();
              history.push(ROUTES.USER_ROUTES.NEW_PROGRAMMING);
            }}
            handleClose={onBack}
          />
        </Grid>
      )}

      <ModalFinalCreateTransport
        open={isModalOpen}
        programNumber={programNumber}
        onClose={() => setModalOpen(false)}
      />
    </Grid>
  );
};

export default ProgrammingSectionThree;
