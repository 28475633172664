import { Box, Typography } from "@mui/material";
import React from "react";

interface StatusTagProps {
  text: string | any;
  type: StatusTagType;
  marginLeft?: string;
}

export enum StatusTagType {
  NONE,
  WARNING,
  SUCCESS,
  ERROR,
  INFO,
}

const StatusTag: React.FC<StatusTagProps> = ({ text, type, marginLeft }) => {
  const getColorStatus = () => {
    switch (type) {
      case StatusTagType.WARNING:
        return "#FFB800";
      case StatusTagType.SUCCESS:
        return "#0BB873";
      case StatusTagType.ERROR:
        return "#D91F05";
      case StatusTagType.INFO:
        return "#0F71D0";
      default:
        return "#3E3D3D";
    }
  };

  return (
    <Box
      display={"inline-block"}
      width={"fit-content"}
      height={"fit-content"}
      paddingY={0.5}
      paddingX={1}
      color={getColorStatus}
      borderRadius={4}
      textAlign={"center"}
      marginLeft={marginLeft}
    >
      <Typography
        fontSize={16}
        style={{
          fontWeight: "bold",
          fontFamily: "DM Sans",
          whiteSpace: "nowrap",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default StatusTag;
