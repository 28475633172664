import { CSSProperties } from "react";

export const iconStyle: CSSProperties = {
  backgroundColor: "#FFF5F7",
  color: "#D91F05",
  fontWeight: 500,
  fontSize: "1.2rem",
  borderRadius: "50%",
  textAlign: "center",
  marginLeft: "1rem",
  padding: ".2rem",
  cursor: "pointer",
};
