import { TypesPermissions } from "@components/Routes/TypesPermissions";
import appConfig from "@config/appConfig";
import { IFilterComissionDTO } from "@modules/comissionCalculator/dtos/IFilterComissionDTO";
import { IFilterAllReclamationDTO } from "@modules/reclamation/dtos/IFilterAllReclamationDTO";
import { SituationTitle } from "@modules/titles/dtos/IQueryTitlesDataDTO";
import { freighTypesEnum } from "@pages/User/MyOrders/models/ENUM_MY_ORDERS";
import {
  addDays,
  format,
  formatDistanceToNow,
  getMonth,
  isSaturday,
  isSunday,
  isValid,
  parseISO,
  startOfDay,
} from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import moment from "moment-timezone";
import { KEY, STATUSTYPE } from "./enum";
import { Theme } from "@material-ui/core";

export type TypeAlign = "center" | "left" | "right";
export enum TypeAlignEnum {
  CENTER = "center",
  LEFT = "left",
  RIGHT = "right",
}

const nameMonth = {
  1: "Janeiro",
  2: "Fevereiro",
  3: "Março",
  4: "Abril",
  5: "Maio",
  6: "Junho",
  7: "Julho",
  8: "Agosto",
  9: "Setembro",
  10: "Outubro",
  11: "Novembro",
  12: "Dezembro",
};

export const invalidCNPJ = "CNPJ com formato invalido";

export const isValidIsoDate = (dateString: string) => {
  const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
  return isoDatePattern.test(dateString);
};

export const removeLeadingZero = (str: string): string => {
  const result = str.replace(/^0+/, "");
  return result;
};

export const cleanParams = (params: {} | string): {} => {
  if (typeof params === "undefined") return "";
  const paramsFiltered = params;
  Object.keys(params).forEach((item) => {
    if (Array.isArray(params[item]) && params[item].length === 0)
      delete params[item];
    if (
      params[item] === undefined ||
      params[item] === null ||
      params[item] === "" ||
      params[item] === ","
    ) {
      delete params[item];
    }
  });
  return paramsFiltered;
};

export const formatTelephone = (telephone: string | undefined): string => {
  if (typeof telephone === "undefined") return "Nao informado";
  if (telephone.length <= 9 || telephone.length >= 12) {
    return telephone;
  }

  const regex = /^(\d{2})(\d{4,5})(\d{4})$/;
  const match = regex.exec(telephone);

  if (!match) {
    throw new Error("O número de telefone não está no formato correto.");
  }

  return `(${match[1]}) ${match[2]}-${match[3]}`;
};

export const formatText = (str: string | undefined) => {
  if (!str) return "Não informado";
  return str.toLowerCase().replace(/(^|\s)\w/g, (letra) => letra.toUpperCase());
};

export const formatDate = (date: string | Date | null): string => {
  if (!date) return "";
  return moment(date).format("YYYY-MM-DD");
};

export const formatHoursGMT = (date: string | Date | null): string => {
  if (!date) return "Formato desconhecido";
  return moment(date).format("HH:mm");
};

export const formatHours = (_date: string | Date | null): string => {
  if (_date) {
    const date = new Date(_date);

    const hours = date.getUTCHours().toString().padStart(2, "0");
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  }

  return "-";
};

export const formatDatePlus = (
  date: string | Date | null | undefined
): string => {
  if (typeof date === "undefined") return "Nao informado";
  return moment(date).format("DD/MM/YYYY");
};

export const formatToIsoDate = (date: string | Date | null): string => {
  return moment(date).toISOString();
};

//Exemplo: 2024-02-01T00:00:00.000Z para 01/02/2024
export function formatTimezoneToDate(dateString: string): string {
  const date = new Date(dateString);
  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = date.getUTCFullYear();
  return `${day}/${month}/${year}`;
}

export const downloadFile = (uriContent: string, name?: string | null) => {
  const link = document.createElement("a");
  link.href = uriContent;
  link.download = name || `Relatório-${moment().format("DD-MM-YYYY")}`;
  link.click();
};

export const downloadPDF = (blob, filename) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export const fileNameByHeaders = (
  headers: Record<string, string | undefined>
) => {
  const headerContentDisposition =
    headers["content-disposition"] || headers["Content-Disposition"] || "";
  const match =
    headerContentDisposition.match(/.*"(.*)".*/) ||
    headerContentDisposition.match(/.*filename=(.*)/);
  if (match) {
    return match[1];
  }

  return null;
};

export const formatCurrencyYourSales = (value: string | undefined) => {
  value = value?.replace(".", "").replace(",", "").replace(/\D/g, "");

  const options = { minimumFractionDigits: 2 };
  if (value) {
    const result = new Intl.NumberFormat("pt-BR", options).format(
      parseFloat(value) / 1000
    );
    return "R$ " + result;
  }

  return "R$ - ";
};

export const formatCurrencyYourSalesWithoutRS = (value: string | undefined) => {
  value = value?.replace(".", "").replace(",", "").replace(/\D/g, "");

  const options = { minimumFractionDigits: 2 };
  if (value) {
    const result = new Intl.NumberFormat("pt-BR", options).format(
      parseFloat(value) / 1000
    );
    return result;
  }

  return "-";
};

export const formatTimeRefuel = (value: number | string) => {
  return value + "s";
};

export const formatCurrencyTwoDigits = (value: number | string) => {
  let valueReturn;
  if (typeof value === "string") {
    valueReturn = parseFloat(value);
  } else {
    valueReturn = value;
  }
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  }).format(valueReturn);
};

export const formatCurrencyPriceWithTwoDigit = (value: number) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  }).format(value);
};

export const formatCurrencyThreeDigits = (value: number | string) => {
  let valueReturn;
  if (typeof value === "string") {
    valueReturn = parseFloat(value);
  } else {
    valueReturn = value;
  }
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 3,
  }).format(valueReturn);
};

export const formatCurrencyPriceProductPlus = (value: number | string) => {
  let valueReturn;
  if (typeof value === "string") {
    valueReturn = parseFloat(value);
  } else {
    valueReturn = value;
  }
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 3,
  }).format(valueReturn);
};

export const formatCurrencyPriceProduct = (value: number) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 4,
  }).format(value);
};

export const formatCurrency = (value: number) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
};

export function removeDots(str: string) {
  return str.replace(/\./g, "");
}

export const numberFormatter = (value: number) => {
  const numberFormatter = Intl.NumberFormat("pt-BR");
  const formatted = numberFormatter.format(value);
  return formatted;
};

export const getMonthAndConvertByString = (value: string) => {
  const number = getMonth(new Date(value));
  if (number) {
    return nameMonth[number];
  }
  return "";
};

export function getStringMonthYear(data: string): string {
  const dataObj = new Date(data);
  const mes = nameMonth[dataObj.getUTCMonth() + 1];
  const ano = dataObj.getUTCFullYear();
  return `${mes}/${ano}`;
}

export const formatCurrency4DecimalsPlace = (value: number) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    maximumFractionDigits: 4,
    minimumFractionDigits: 4,
  }).format(value);
};

export const diffSet = (setA: string[], setB: string[]) => {
  let _diferenca = new Set(setA);
  for (let elem of setB) {
    _diferenca.delete(elem);
  }
  return _diferenca;
};

export const maskCNPJ = (CNPJ?: string): string => {
  if (!CNPJ) return "";
  if (!CNPJ.length) return "";
  if (CNPJ.length === 14) {
    return CNPJ.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      "$1.$2.$3/$4-$5"
    );
  }
  return invalidCNPJ;
};

export const unmaskCNPJ = (cnpj?: string): string => {
  if (!cnpj) return "";
  return cnpj.replace(/[^\d]/g, "");
};

export const maskCNPJFromNewUser = (CNPJ?: string): string => {
  if (!CNPJ) return "";
  if (!CNPJ.length) return "";
  let masked = CNPJ.length > 14 ? CNPJ.slice(0, 14) : CNPJ;
  masked = masked.replace(/^(\d{2})(\d)/, "$1.$2");
  masked = masked.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
  masked = masked.replace(/\.(\d{3})(\d)/, ".$1/$2");
  masked = masked.replace(/(\d{4})(\d)/, "$1-$2");
  return masked;
};

export const capitalizeWords = (words: string) => {
  if (!words) return "";
  const wordsUpperCase = words
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
  return wordsUpperCase;
};

export const goToLogout = () => {
  window.localStorage.clear();
  window.sessionStorage.clear();

  const hostname = window.location.hostname;
  if (process.env.REACT_APP_ENV === "development" || hostname === "localhost") {
    window.location.href = appConfig.cognito.urlLogout.development;
  } else if (Boolean(window.location.origin.split(".")[0].match(/-dev$/))) {
    window.location.href = appConfig.cognito.urlLogout.homologation;
  } else {
    window.location.href = appConfig.cognito.urlLogout.production;
  }
};

export const getCorrectDate = (date: Date) => {
  const dateDtOnly = new Date(
    date.valueOf() + date.getTimezoneOffset() * 60 * 1000
  );
  return dateDtOnly;
};

export const nameSituationTitle = (situationTitle: SituationTitle) => {
  switch (situationTitle) {
    case "closed":
      return "Baixado";
    case "partial":
      return "Parcial";
    case "due":
      return "Vencido";
    case "open":
      return "A vencer";
  }
};

export const formatAmount = (raw_value: number) => {
  return Intl.NumberFormat("pt-BR").format(raw_value);
};

export function performCPFMask(cpf: string): string {
  cpf = cpf.replace(/\D/g, "");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  return cpf;
}

export const isValidCPFOrCPNJ = (data: string) => {
  return data.length === 11 || data.length === 14;
};

export const formatCNPJOrCPF = (data: string) => {
  switch (data.length) {
    case 11:
      return performCPFMask(data);
    case 14:
      return maskCNPJFromNewUser(data);
    default:
      return data.length > 14 ? "Valor inválido" : data;
  }
};

export function performBRPhoneMask(phone: string) {
  phone = phone.replace(/[\D|\s]/g, "").substring(0, 11);
  phone = phone.replace(/^(\d{2})(\d)/, "($1) $2");
  phone = phone.replace(/^\((\d{2})\) (\d{5})(\d)/, "($1) $2-$3");
  return phone;
}

export function capitalizeFirstLetter(str: string) {
  // converting first letter to uppercase
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

  return capitalized;
}

export function capitalizeFirstLetterFullString(str?: string): string {
  if (!str) return "";
  const arr = str.toLowerCase().split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const capitalized = arr.join(" ");
  return capitalized;
}

// Function to calculate how many pages the table will have. Returning an integer.
export const calculateRange = (data: Object[], rowsPerPage: number) => {
  const range: number[] = [];
  const num = Math.ceil(data.length / rowsPerPage);
  let i = 1;
  for (i = 1; i <= num; i++) {
    range.push(i);
  }
  return range;
};

// Data slice function takes as parameter an array of Objects, the page of the table, and how many items per page.
// With this, it gives a slice to the array, so that a new array is returned, with it, we will be able to check the size of the array, to use it in the tableFooter
export const sliceData = (
  data: Object[],
  page: number,
  rowsPerPage: number
) => {
  return data.slice((page - 1) * rowsPerPage, page * rowsPerPage);
};

export function formatDateWithoutHours(
  dt: string,
  format: string = "DD/MM/YYYY"
) {
  return moment(dt).format(format);
}

export function formatDateWithoutHoursPlus(
  dt: Date,
  format: string = "DD/MM/YYYY"
) {
  return moment(dt).format(format);
}

export function formatDateWithTZ(dt: string) {
  if (dt === "") {
    return dt;
  } else {
    return `${dt}T04:00:00.000Z`;
  }
}

export function formatDateWithTZPlusOneDay(dt: string) {
  const oneDay = 60 * 60 * 24 * 1000 * 2;

  let dateFormatTotime = new Date(dt);
  let increasedDate = new Date(dateFormatTotime.getTime() + oneDay);

  let endDate = moment(increasedDate).format("yyyy-MM-DD");
  return `${endDate}T03:59:59.000Z`;
}

export function replaceCPF(cpf: string) {
  return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1-$4");
}

// Ex: "JOÃO DA SILVA" -> "João da Silva"
export function formatAllFistLetterCapitalized(inputStr: string): string {
  if (!inputStr) return "";
  if (inputStr.length === 0) return inputStr;
  if (typeof inputStr !== "string") return "";

  // Quebra a string em palavras
  const words = inputStr.replace("-", " ").split(" ");

  // Inicializa uma lista para armazenar as palavras formatadas
  const formattedWords: string[] = [];

  // Itera sobre as palavras e formata cada uma
  for (const word of words) {
    // Converte a primeira letra em maiúscula e as demais em minúscula
    const formattedWord =
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    formattedWords.push(formattedWord);
  }

  // Junta as palavras formatadas em uma única string e retorna o resultado
  return formattedWords.join(" ");
}

export function getHoursFromISODate(isoDate: Date): string {
  const date = moment(isoDate);
  const hours = date.format("HH:mm:ss");
  return hours;
}

export function validatePlate(plate: string) {
  plate = plate.replace(/\W/g, "").toUpperCase();
  plate = plate.substring(0, 7);

   // NOTE: Verifica se a placa está no formato Mercosul (ABC1D34)
   const isPlateMercoSul = regexPlateMerc.test(plate);
   // NOTE: Verifica se a placa está no formato (ABC1D34)
   const isPlate = regexPlate.test(plate);

  if(isPlateMercoSul || isPlate){
   return true;
  }
  return false;
}



export function maskVehiclePlateMercoSul(plate: string): string {

  const isValid = validatePlate(plate);
  const plateToUpperCase = plate.replace(/\W/g, "").toUpperCase();
   if(isValid){
    return plateToUpperCase.replace(/([A-Z]{3})(.*)/, "$1-$2").substring(0, 8);
   }

   return plateToUpperCase; 
}

const regexPlate =  /^[A-Z]{3}[0-9]{4}$/;
const regexPlateMerc = /^[A-Z]{3}[0-9][A-Z][0-9]{2}$/;






export function createOptionListPaymentDay(
  tamanho: number
): { key: string; value: string }[] {
  const vetor: { key: string; value: string }[] = [];

  for (let i = 0; i <= tamanho; i++) {
    vetor.push({
      key: "000" + String(i),
      value:
        i === 0
          ? "A vista"
          : i === 1
          ? `${i.toString()} Dia`
          : `${i.toString()} Dias`,
    });
  }

  return vetor;
}

export function createOptionListAmount(
  lenght: number
): { key: string; value: string }[] {
  const listQuantity: { key: string; value: string }[] = [];

  for (let i = 1; i <= lenght; i++) {
    listQuantity.push({ key: String(i), value: i.toFixed(3) });
  }
  listQuantity.push({ key: "12", value: "12.000" });
  listQuantity.push({ key: "13", value: "13.000" });
  listQuantity.push({ key: "14", value: "14.000" });
  listQuantity.push({ key: "15", value: "15.000" });
  listQuantity.push({ key: "16", value: "17.000" });
  listQuantity.push({ key: "17", value: "20.000" });
  listQuantity.push({ key: "18", value: "22.000" });
  listQuantity.push({ key: "19", value: "23.000" });
  listQuantity.push({ key: "20", value: "24.000" });
  listQuantity.push({ key: "21", value: "30.000" });
  listQuantity.push({ key: "22", value: "38.500" });
  listQuantity.push({ key: "23", value: "44.000" });
  listQuantity.push({ key: "24", value: "45.000" });
  listQuantity.push({ key: "25", value: "46.000" });
  listQuantity.push({ key: "26", value: "50.000" });
  listQuantity.push({ key: "27", value: "52.000" });
  listQuantity.push({ key: "28", value: "57.500" });
  listQuantity.push({ key: "29", value: "60.000" });
  listQuantity.push({ key: "30", value: "62.000" });
  listQuantity.push({ key: "31", value: "63.000" });

  return listQuantity;
}

export function applyTaxAccordingFreight(
  freightType: string,
  price: string,
  freight: string
): number {
  if (freightType === "CIF") {
    return parseFloat(price) + parseFloat(freight);
  } else {
    return parseFloat(price);
  }
}

export function subtotalApplyTaxAccordingFreight(
  qtd: number = 1,
  freightType: string,
  price: string,
  freight: string,
  fixed: number = 2
): number {
  const parsedPrice = parseFloat(price);
  const parsedFreight = parseFloat(freight);
  if (isNaN(parsedPrice) || isNaN(parsedFreight)) {
    return 0;
  }

  // Apply freight tax if freightType is "CIF"
  if (freightType === "CIF") {
    return toFixedParseString(qtd * (parsedPrice + parsedFreight), fixed);
  }
  return toFixedParseString(qtd * parsedPrice, fixed);
}

export function toFixedParseString(num: number, value: number = 2) {
  return parseFloat(num.toFixed(value));
}

export const transformTextNozzles = (nozzles: string[]): string => {
  const nozzlesText = nozzles.map((nozzle, index) => {
    return index === 0 ? `Bico ${nozzle}` : ` bico ${nozzle}`;
  });
  return nozzlesText.toString();
};

export const formatedLegalName = (name: string) => {
  return name
    .split(" ")
    .map((name) => name[0] + name.slice(1).toLowerCase())
    .join(" ");
};

export function cutText(description: string, numberVisibleCharacters: number) {
  if (description.length <= numberVisibleCharacters) {
    return description;
  } else {
    return `${description.slice(0, numberVisibleCharacters)}...`;
  }
}

export const FileTransformDataImage = async (file: File) => {
  const formData = new FormData();

  // Usar FileReader para ler o arquivo e adicionar ao FormData
  await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      formData.append("image", file);
      resolve(event.target?.result);
    };
    reader.onerror = (event) => {
      reject(event);
    };
    reader.readAsDataURL(file);
  });

  return formData;
};

export const hexToRgba = (hex: string, alpha: number) => {
  hex = hex.replace(/^#/, "");

  if (hex.length === 3) {
    hex = hex
      .split("")
      .map(function (char) {
        return char + char;
      })
      .join("");
  }

  const red = parseInt(hex.substring(0, 2), 16);
  const green = parseInt(hex.substring(2, 4), 16);
  const blue = parseInt(hex.substring(4, 6), 16);

  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
};

export function formatDateToStringBr(dateString: string): string {
  const isValidDate = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/.test(
    dateString
  );

  if (isValidDate) {
    const daysWeek = [
      "Domingo",
      "Segunda-feira",
      "Terça-feira",
      "Quarta-feira",
      "Quinta-feira",
      "Sexta-feira",
      "Sábado",
    ];

    const date = new Date(dateString);
    const day = date.getUTCDate();
    const mes = date.getUTCMonth() + 1;
    const dayOfWeek = daysWeek[date.getUTCDay()];

    return `${day.toString().padStart(2, "0")}/${mes
      .toString()
      .padStart(2, "0")} (${dayOfWeek})`;
  }

  // Se não for um formato de data válido, retorna a string original
  return dateString;
}

export const sanitizeParameters = (
  data: IFilterAllReclamationDTO
): Record<string, unknown> => {
  const entries = Object.entries(data).filter(([, value]) => !!value);
  return Object.fromEntries(entries);
};

export const sanitizeParametersComission = (
  data: IFilterComissionDTO
): Record<string, unknown> => {
  const entries = Object.entries(data).filter(([, value]) => !!value);
  return Object.fromEntries(entries);
};

export const randomNumber = () => {
  const min = 100000; // Menor número de 6 dígitos (100000)
  const max = 999999; // Maior número de 6 dígitos (999999)
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const reduceUUID = (str: string) => {
  // exemplo -> "https://atem-nozzles.s3.amazonaws.com/image_nozzles/511dac7f-f304-43c8-b421-ef5a26635ddc.jpg";

  const parts = str.split("/");
  const lastPart = parts[parts.length - 1];
  const extractedString = lastPart.split("-")[0];

  // Isso irá extrair "511dac7f"
  return extractedString;
};

export const waiting = async (time: number) => {
  await new Promise((resolve) => setTimeout(resolve, time));
};

export const toReplaceAsterisk = (permission: string): string[] => {
  const permissionMethods = ["read", "write"];
  const replaceResult: string[] = [];

  if (/\*/.test(permission)) {
    permissionMethods.forEach((replace) => {
      const valueReplaced = permission.replace(/\*/, replace);
      replaceResult.push(valueReplaced);
    });
  } else {
    replaceResult.push(permission);
  }
  return replaceResult;
};

export const generatePermissionMatcher = (permissionRef: string) => {
  const exp = new RegExp(
    permissionRef
      .replace(/(:\*)/g, "(:(.+)?)?")
      .replace(/READ$/g, "(read|write)")
  );

  const r = (permissionRef: string) => {
    return Boolean(permissionRef.match(exp));
  };

  return r;
};

export const resetTimeAndFormat = (data: Date): string => {
  data.setHours(0, 0, 0, 0);
  const dataFormatada = `${data.toISOString().slice(0, -1)}999Z`;

  return dataFormatada;
};

export const range = (start: number, end: number) => {
  return Array.from({ length: end - start }, (v, k) => k + start);
};

export function dateRangeFormat(
  startDate?: Date | string | null,
  endDate?: Date | string | null
) {
  if (startDate === null && endDate === null) {
    return "";
  }
  const startDateFormat: string =
    startDate instanceof Date ? format(startDate, "dd/MM/yyyy") : "";

  const endDateFormat: string =
    endDate instanceof Date ? format(endDate, "dd/MM/yyyy") : "";

  return `${startDateFormat} a ${endDateFormat}`;
}

export const formatToLiter = (liter: number): string => {
  if (isNaN(liter) || liter < 0) {
    return "-";
  }

  return liter
    .toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 3,
    })
    .replace(/,/g, ".");
};

export const formatToReal = (valor: number): string => {
  if (isNaN(valor) || typeof valor !== "number") {
    return "-";
  }

  const valorFormatado = valor.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return valorFormatado;
};

export const convertYearMonthDay = (data) => {
  const ano = data?.getFullYear();
  const mes = String(data?.getMonth() + 1).padStart(2, "0");
  const dia = String(data?.getDate()).padStart(2, "0");

  return `${ano}/${mes}/${dia}`;
};
export interface IEntry {
  totalVolume?: number;
  totalBilling?: number;
  avgPrice?: number;
}
export function getRelevantValue(entry: IEntry) {
  if (
    !entry ||
    (entry.totalVolume === undefined &&
      entry.totalBilling === undefined &&
      entry.avgPrice === undefined)
  ) {
    return 0;
  }
  return entry.totalVolume ?? entry.totalBilling ?? entry.avgPrice ?? 0;
}

export const getTodayDate = () => {
  const date = new Date();
  const year = date?.getFullYear();
  const month = date?.getMonth() + 1;

  const formattedFirstDay = [
    year,
    month.toString().padStart(2, "0"),
    "01",
  ].join("/");

  return formattedFirstDay;
};

export const getLastDayOfMonth = () => {
  const date = new Date();
  const year = date?.getFullYear();
  const month = date?.getMonth() + 1;
  const lastDay = new Date(year, month, 0).getDate();

  const formattedLastDay = [
    year,
    month.toString().padStart(2, "0"),
    lastDay.toString().padStart(2, "0"),
  ].join("/");

  return formattedLastDay;
};

export const convertDateInFormatZ = (dataString: string | Date): string => {
  const dateOrigin = new Date(dataString);

  if (isNaN(dateOrigin.getTime())) {
    throw new Error("Data inválida");
  }

  dateOrigin.setHours(0, 0, 0, 0);

  const year = dateOrigin.getUTCFullYear();
  const month = String(dateOrigin.getUTCMonth() + 1).padStart(2, "0");
  const day = String(dateOrigin.getUTCDate()).padStart(2, "0");

  const formatDate = `${year}-${month}-${day}T${"23:59:59.999Z"}`;
  return formatDate;
};

export const firstDayOfMonth = (dataString: string): string => {
  const dateOrigin = new Date(dataString);
  if (isNaN(dateOrigin.getTime())) {
    throw new Error("Data inválida");
  }

  dateOrigin.setDate(1);
  dateOrigin.setHours(0, 0, 0, 0);

  const year = dateOrigin.getUTCFullYear();
  const month = String(dateOrigin.getUTCMonth() + 1).padStart(2, "0");
  const day = String(dateOrigin.getUTCDate()).padStart(2, "0");

  const formatDate = `${year}-${month}-${day}T${"23:59:59.999Z"}`;
  return formatDate;
};

// Function to display the month and year in the format "January/2024"
export function formatMonthYear(date: Date): string {
  const monthNumber = date?.getMonth() + 1; // getMonth returns a value from 0 to 11
  const year = date?.getFullYear();
  const monthName = nameMonth[monthNumber];

  return `${monthName}/${year}`;
}

export const formatDateSchedule = (date?: Date | null) => {
  if (!date) return "";
  const dayFormatted = String(date.getDate()).padStart(2, "0");
  const monthFormatted = String(date.getMonth() + 1).padStart(2, "0");
  return `${dayFormatted}/${monthFormatted}/${date?.getFullYear()}`;
};

export const formatTimeSchedule = (date?: Date | null) => {
  if (!date) return "";
  const hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${hours}:${minutes}`;
};

export function formatToAmountLiters(number: string | unknown): string {
  const parsedNumber = parseFloat(number as string);

  if (isNaN(parsedNumber)) {
    return "Não informado";
  }

  const formattedNumber = parsedNumber.toLocaleString("pt-BR", {
    maximumFractionDigits: 3,
  });

  return `${formattedNumber}L`;
}

// Função para obter a data de hoje no formato desejado
export function getToday(): string {
  return moment().subtract(1, "months").format("YYYY-MM-DD");
}

export function formatDateYYYYMMDD(date?: Date | null | string): string {
  if (!date) return "";
  return moment(date).format("YYYY-MM-DD");
}

// Função para obter a data daqui a um mês no formato desejado no formato String
export function getDateInOneMonth(): string {
  return moment().add(1, "months").format("YYYY-MM-DD");
}

export function mergeDateTime(day?: string, time?: string): string {
  if (!day) return "Data Invalida";
  if (!time) return "Hora Invalida";

  const deliveryDateTime = new Date(`${day}T${time}`);
  // Verifica se a data é válida
  if (!isNaN(deliveryDateTime.getTime())) {
    // Formata a data e hora no formato UTC
    const formattedDeliveryDateTime = deliveryDateTime.toISOString();
    return formattedDeliveryDateTime;
  }

  return "formato de data invalido";
}

export function identifyIntervalPattern(
  timeObject: { [time: string]: number } | undefined
): number | null | undefined {
  if (!timeObject) return undefined;
  let commonInterval: number | null = null;

  const calculateGCD = (a: number, b: number): number =>
    b === 0 ? a : calculateGCD(b, a % b);

  for (const time in timeObject) {
    if (Object.prototype.hasOwnProperty.call(timeObject, time)) {
      const minutes = parseInt(time.split(":")[1]);

      if (commonInterval === null) {
        commonInterval = minutes;
      } else {
        commonInterval = calculateGCD(commonInterval, minutes);
      }
    }
  }

  return commonInterval;
}

export function labelDuplicatas(
  array: { value: string; label: string }[]
): { value: string; label: string }[] {
  const labels = array.map((item) => {
    const checkDuplicata = array.filter((item2) => item2.label === item.label);
    if (checkDuplicata.length > 1) {
      return {
        value: item.value,
        label: item.label + "-" + item.value,
      };
    } else {
      return item;
    }
  });
  return labels;
}

// Função para verificar se duas datas representam dias diferentes, sem considerar os horários
export function compareDatesIgnoringTime(
  datePrev?: Date | null,
  dateNext?: Date | null
) {
  if (!datePrev || !dateNext) {
    return false; // ou qualquer outro tratamento que você deseja para datas faltantes
  }

  const date1WithoutTime = moment(datePrev).startOf("day");
  const date2WithoutTime = moment(dateNext).startOf("day");

  return !date1WithoutTime.isSame(date2WithoutTime);
}

// Função para verificar se duas datas representam horários diferentes, ignorando os dias
export function compareTimesIgnoringDate(
  datePrev: Date | null,
  dateNext: Date
) {
  const time1 = moment(datePrev).format("HH:mm:ss");
  const time2 = moment(dateNext).format("HH:mm:ss");
  return time1 !== time2;
}

export function getMidnightDate(date) {
  return new Date(date?.getFullYear(), date?.getMonth(), date?.getDate());
}

export function compareEqualHours(timeString: string, date: Date): boolean {
  const [hour, minute] = timeString.split(":").map(Number); // Extrai horas e minutos da string de horário
  const dateHour = date.getHours(); // Obtém as horas do objeto Date
  const dateMinute = date.getMinutes(); // Obtém os minutos do objeto Date
  return dateHour === hour && dateMinute === minute; // Compara as horas e minutos
}

export function filterByFutureDate(arr: string[]) {
  const today = new Date();

  const currentDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  return arr.filter((item) => {
    const [year, month, day] = item.split("-").map(Number); // Dividindo a string em ano, mês e dia
    const itemDate = new Date(year, month - 1, day); // Construindo a data sem considerar o fuso horário local
    return itemDate >= currentDate;
  });
}

// NOTE: Referencias para logica de TypesPermissions.DRIVER - https://atemdevs.atlassian.net/wiki/spaces/PDCA/pages/27688972/Vis+o+do+Perfil+Motorista
// O usuario motorista <> se e somente se <> tem role "portal_cliente" e tem somente o modulo "driver"
export function checkOnlyDriverPermission(
  systemModules: string[],
  roles: string[]
): boolean {
  const checkPermissionPortalCliente = roles.some(
    (role) => role === TypesPermissions.PORTAL_CLIENTE
  );
  const checkHasOnlyDriverModule =
    systemModules.length === 1 &&
    systemModules.some(
      (module) => module === TypesPermissions.DRIVER.replace(":*", "")
    );

  return checkPermissionPortalCliente && checkHasOnlyDriverModule
    ? true
    : false;
}

// Verifica se o usuario tem permissão de portal_cliente:driver(Motorista) ou portal_cliente:contact(Fale conosco)
export function checkDriverOrContactPermission(
  systemModules: string[],
  roles: string[]
): boolean {

  const checkPermissionPortalCliente = roles.some(
    (role) => role === TypesPermissions.PORTAL_CLIENTE
  );

  //NOTE: DRIVER(Motorista)
  const checkHasOnlyDriverModule = checkOnlyDriverPermission(systemModules, roles)

  //NOTE: CONTACT(Fale conosco)
  const checkHasOnlyCostumerModule =
    systemModules.length > 0 &&
    systemModules.some(
      (module) => module === TypesPermissions.CONTACT.replace(":*", "")
    );

    const checkDriverOrCustomer =  checkHasOnlyDriverModule || checkHasOnlyCostumerModule

  return checkPermissionPortalCliente && checkDriverOrCustomer
    ? true
    : false;
}

// O usuario Transportadora se o SYSTEM_MODULES possui modulo carrier =>  "portal_cliente:carrier"
export function checkCarrierPermission(
  systemModules: string[],
  roles: string[]
): boolean {
  const checkPermissionPortalCliente = roles.some(
    (role) => role === TypesPermissions.PORTAL_CLIENTE
  );

  const checkHasCarrierModule =
    systemModules.length > 0 &&
    systemModules.some(
      (module) => module === TypesPermissions.CARRIER.replace(":*", "")
    );
  return checkPermissionPortalCliente && checkHasCarrierModule ? true : false;
}

export const convertStringToOptions = (
  array: {}[] | undefined,
  id: string,
  name: string
) => {
  if (!array) return [];
  return array.map((item) => {
    return {
      value: item[id],
      label: item[name],
    };
  });
};

export const sortByNameInAlphabeticalOrder = <T extends { name: string }>(
  array: T[]
) => {
  return array.sort((a, b) => a.name.localeCompare(b.name));
};

export const sortByDeliveryDate = <T extends { deliveryDate: string }>(
  array?: T[]
) => {
  if (!array) return;
  return array.sort(
    (a, b) =>
      new Date(a.deliveryDate).getTime() - new Date(b.deliveryDate).getTime()
  );
};

export const arrayToStringJoin = <T extends { [key: string]: any }>(
  array?: T[] | undefined,
  key?: string
): string | undefined => {
  if (!array) return undefined;
  if (!key) return undefined;

  const filteredArray = array.filter((item) => item[key] !== undefined);

  if (filteredArray.length === 0) return undefined;
  if (filteredArray.length === 1) return filteredArray[0][key];

  return filteredArray.map((item) => item[key]).join(",");
};

export const isValidAvailableHours = (obj): boolean => {
  // Verifica se obj não é uma array e ainda é um objeto
  if (Array.isArray(obj) || typeof obj !== "object" || obj === null) {
    return false;
  }

  for (const key in obj) {
    if (!/^([0-1]?[0-9]|2[0-3]):00$/.test(key)) {
      return false;
    }
    if (typeof obj[key] !== "number") {
      return false;
    }
  }

  return true;
};

export function extractDate(dateTimeString?: string): string {
  if (!dateTimeString) return "";
  const d = new Date(dateTimeString);
  return d.toISOString().split("T")[0]; // Pega a data no formato 'YYYY-MM-DD'
}

export function extractTime(dateTimeString?: string): string {
  if (!dateTimeString) return "";
  const t = new Date(dateTimeString);
  return t.toISOString().split("T")[1].substring(0, 5); // Pega o horário no formato 'HH:mm:ss'
}

export function formatDateDistanceNow(dateInput?: Date | string): string {
  if (!dateInput) {
    return "";
  }

  let date: Date;

  if (typeof dateInput === "string") {
    // Tenta converter a string para um objeto Date
    date = parseISO(dateInput);
  } else {
    date = dateInput;
  }

  if (!isValid(date)) {
    return "";
  }

  const now = new Date();

  // Calcular a diferença em minutos
  const differenceInMinutes = Math.floor(
    (now.getTime() - date.getTime()) / (1000 * 60)
  );
  if (differenceInMinutes < 60) {
    // Se a diferença for menor que uma hora
    if (differenceInMinutes === 1) {
      return `Há ${differenceInMinutes} minuto`;
    } else {
      return `Há ${differenceInMinutes} minutos`;
    }
  }

  const differenceInDays = Math.floor(differenceInMinutes / (60 * 24));

  if (differenceInDays === 0) {
    let distance = formatDistanceToNow(date, { addSuffix: true, locale: ptBR });
    return distance.charAt(0).toUpperCase() + distance.slice(1);
  } else if (differenceInDays === 1) {
    return "Há 1 dia"; // Se for exatamente um dia
  } else if (differenceInDays < 7) {
    return `Há ${differenceInDays} dias`; // Se for menos de uma semana
  } else {
    return format(date, "dd/MM/yyyy", { locale: ptBR }); // Caso contrário, formata a data
  }
}

export const formatPaymentValue = (value: string | unknown) => {
  if (typeof value !== "string") return value;
  const paymentDay = parseInt(value.match(/\d+/)?.[0] || value, 10);
  if (paymentDay === 0 || value === "A vista") return "À vista";
  if (paymentDay === 1) return "1 Dia";
  return `${paymentDay} Dias`;
};

export function getDateInOneWeek(
  date: Date,
  direction: "forward" | "backward"
): string {
  const resultDate = new Date(date);
  if (direction === "forward") {
    resultDate.setDate(resultDate.getDate() + 7);
  } else {
    resultDate.setDate(resultDate.getDate() - 7);
  }
  return resultDate.toISOString().split("T")[0];
}


export function getDateInOneWeekTypeDate(
  date: Date,
  direction: "forward" | "backward"
): Date {
  const resultDate = new Date(date);
  if (direction === "forward") {
    resultDate.setDate(resultDate.getDate() + 7);
  } else {
    resultDate.setDate(resultDate.getDate() - 7);
  }
  return resultDate;
}

export function calculateMinDate(freightType: string): Date {
  const now = new Date();
  const currentHour = now.getHours();
  let minDate = startOfDay(now);

  if (isSaturday(now) || isSunday(now)) {
    minDate = startOfDay(addDays(now, isSaturday(now) ? 2 : 1));
  } else {
    if (freightType === freighTypesEnum.CIF && currentHour >= 13) {
      minDate = startOfDay(addDays(now, 1));
    } else if (freightType === freighTypesEnum.FOB && currentHour >= 16) {
      minDate = startOfDay(addDays(now, 1));
    }
  }
  return minDate;
}

export const formatDateAndHourPTBR = (
  dataString?: string,
  timezone?: string
) => {
  if (!dataString) return "indefinido";

  const dataBrasil = moment(dataString).tz(timezone ?? "America/Manaus");
  const dataFormatada = dataBrasil.format("DD/MM/YYYY");
  const horaFormatada = dataBrasil.format("HH:mm");

  return `${dataFormatada}\n às ${horaFormatada}`;
};

export const formatDateWithSlash = (date: string | unknown): string => {
  if (typeof date === "string") {
    return moment(date).format("DD/MM/YYYY");
  }
  return "Formato desconhecido";
};

export const getInitialLettersName = (name: string) => {
  if (!name || name.trim().length === 0) return "";
  const splitedName = name.split(" ");
  let letters = "";
  for (let splited of splitedName) {
    letters += splited[0];
  }
  return letters;
};

export const applyColor = (key: string | number | symbol, value: number | string | Date, theme: Theme) => {
  switch (key) {
    case KEY.SCHEDULEDATE:
      const today = new Date();
      const tomorrow = addDays(new Date(), 1);
      const deliveryDate = new Date(value);

      const isToday = deliveryDate.toDateString() === today.toDateString();
      const isTomorrow =
        deliveryDate.toDateString() === tomorrow.toDateString();

      let colorReturn = "";
      switch (true) {
        case isToday:
          colorReturn = theme.palette.success.light;
          break;
        case isTomorrow:
          colorReturn = theme.palette.secondary.light;
          break;
        default:
          colorReturn = theme.palette.text.primary;
          break;
      }

      return colorReturn;

    case KEY.STATUS:
      switch (value) {
        case STATUSTYPE.FATURADO:
          return theme.palette.success.light;

        case STATUSTYPE.LIBERADO:
          return theme.palette.secondary.light;

        case STATUSTYPE.BLOQUEADO:
          return theme.palette.error.light;

        default:
          return theme.palette.text.primary;
      }
    default:
      return "auto";
  }
};

export const isNotEmpty = <T>(array: T[]) => Boolean(array.length);
