import React, { useEffect, useMemo, useState } from "react";
import { Box, Grid, IconButton } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import { CircularProgress, useTheme } from "@material-ui/core";
import {
  TrashIconPlus,
  VisibleIconBlack,
  RoadLoadRedFullIcon,
  ArrowDownIconPlus,
  AddIcon,
  EditIconBlack,
  RoadBusTransitIcon,
} from "@components/Icons";
import Modal from "@components/Modal";
import Button from "@components/Button";

import {
  TableBodyTypography,
  TableHeadTypography,
  TypographyCaption,
  TypographySubtitle,
  TypographyTitle,
} from "../typography";

import { TableUnscheduleProps } from "./TableSalesOrderGrouped";
import { useRoadLoad } from "../../context/RoadLoadContext";
import ModalDeleteOrder from "../modal/ModalDeleteOrder";
import { IScheduledLoad, StatusSchedule } from "../../model";

import {
  formatDateAndHourPTBR,
  formatToAmountLiters,
  maskCNPJ,
} from "@utils/index";
import TableSalesOrderMobile from "./TableSalesOrderMobile";
import TableEmpty from "./TableEmpty";

import ModalCancelScheduling from "../modal/ModalCancelScheduling";
import ModalSchedule from "../modal/ModalSchedule";

import { STATUSROADLOAD, STATUSTYPE } from "@utils/enum";
import { applyColor } from "./tableUtils";
import ModalCreatedOrder from "../modal/ModalCreatedOrder";
import ModalCancelProgramming from "../modal/ModalCancelProgramming";
import FloatingMenuList from "@components/FloatingMenuListMobile";
import { useHistory } from "react-router-dom";
import ROUTES from "@config/routes";
import { useFlags } from "flagsmith/react";
import { useProgrammingTransport } from "@context/ProgrammingTransport/ProgrammingTransportContext";
import { DeliveryType } from "@utils/DeliveryType";

interface Props<T> {
  children?: React.ReactNode;
  rows?: T[];
  deleteOrder: (groupIndex: number, orderIndex: number) => Promise<void>;
  addOrder: (groupIndex: number, ordersSelected) => Promise<void>;
  tableUnscheduled?: TableUnscheduleProps;
  status: string;
  hiddenPdfReport?: boolean;
}

enum ModalTypes {
  INFORMATION = "Informações",
  SCHEDULE_CHARGING = "Programar Carregamento",
  EDIT_PROGRAMMING = "Editar Programação",
  CANCEL_PROGRAMMING = "Cancelar Programação",
  DOWNLOAD_PROOF = "Baixar comprovante",
}

const TableSalesOrderGroupedMobile: <T>(
  props: Props<T>
) => React.ReactElement = (props) => {
  const history = useHistory();
  const theme = useTheme();

  const {
    rows,
    deleteOrder,
    tableUnscheduled,
    addOrder,
    status,
    hiddenPdfReport,
  } = props;

  const {
    loading: contextLoading,
    roadsSelected,
    updateRoadSelected,
    handleRoadLoadMethod,
    roadCreated,
  } = useRoadLoad();

  const [loadAddOrder, setLoadAddOrder] = useState<boolean>(false);
  const {
    rowSelected,
    setRowSelected,
    deleteTransport,
  } = useProgrammingTransport();

  const featureFlagProgrammingTransport = useFlags([
    "user_sidebar_programming_transport",
  ]).user_sidebar_programming_transport.enabled;

  const [openInformation, setOpenInformation] = useState(false);
  const [openMenuList, setOpenMenuList] = useState(false);
  const [openCancelProgrammingModal, setOpenCancelProgrammingModal] = useState(
    false
  );
  const [hasProgramming, setHasProgramming] = useState<boolean>();
  const containsProgramming = featureFlagProgrammingTransport && hasProgramming;
  const isFOB = rowSelected?.freight === DeliveryType.FOB;

  useEffect(() => {
    setHasProgramming(
      rowSelected?.Status_Programacao === StatusSchedule.PROGRAMMED
    );
  }, [rowSelected]);

  const isScheduled = status === STATUSROADLOAD.SCHEDULED;
  const isFinished = status === STATUSROADLOAD.FINISHED;
  const isCanceled = status === STATUSROADLOAD.CANCELED;

  const buttonData = useMemo(() => {
    const baseButtons = [
      {
        label: "Informações do Agendamento",
        modalTitle: ModalTypes.INFORMATION,
      },
    ];

    if (containsProgramming) {
      baseButtons.push(
        {
          label: "Editar Programação",
          modalTitle: ModalTypes.EDIT_PROGRAMMING,
        },
        {
          label: "Cancelar Programação",
          modalTitle: ModalTypes.CANCEL_PROGRAMMING,
        },
        {
          label: "Baixar comprovante",
          modalTitle: ModalTypes.DOWNLOAD_PROOF,
        }
      );
    }

    if (featureFlagProgrammingTransport && !hasProgramming) {
      if (isFOB) {
        baseButtons.push({
          label: "Programar Carregamento",
          modalTitle: ModalTypes.SCHEDULE_CHARGING,
        });
      }
      baseButtons.push({
        label: "Baixar comprovante",
        modalTitle: ModalTypes.DOWNLOAD_PROOF,
      });
    }

    return baseButtons;
  }, [
    featureFlagProgrammingTransport,
    hasProgramming,
    containsProgramming,
    isFOB,
  ]);

  const handleOpenMenu = (row, indexRow) => {
    setRowSelected({ ...row, index: indexRow });
    isScheduled ? handleMenuModal() : handleOpenInformationModal();
  };

  const handleCloseCancelProgrammingModal = async () => {
    if (!rowSelected?.scheduleGroupID) {
      console.error("scheduleGroupID is missing in rowSelected");
      return;
    }

    try {
      await deleteTransport(rowSelected.scheduleGroupID);
      setOpenCancelProgrammingModal(false);
      handleRoadLoadMethod.fetchScheduled();
    } catch (error) {
      throw error;
    }
  };

  const handleOpenInformationModal = () => {
    setOpenInformation(true);
  };

  const handleCloseInformationModal = () => {
    setRowSelected(null);
    setOpenInformation(false);
  };

  const handleMenuModal = () => {
    setOpenMenuList(true);
  };

  const handleCloseMenuModal = () => {
    setOpenMenuList(false);
  };

  const handleDeleteOrder = async (groupIndex, orderIndex: number) => {
    try {
      if (typeof groupIndex === "undefined") return;
      if (typeof orderIndex === "undefined") return;
      await deleteOrder(groupIndex, orderIndex);
    } catch (err) {
      throw err;
    }
  };

  const handleAddOrder = async () => {
    setLoadAddOrder(true);
    if (rowSelected && typeof rowSelected.index !== "undefined") {
      await addOrder(rowSelected.index, roadsSelected);
    }
    setLoadAddOrder(false);
  };

  const shouldRenderCancelScheduling = (status: string): boolean => {
    return (
      status !== STATUSROADLOAD.CANCELED && status !== STATUSROADLOAD.FINISHED
    );
  };

  const handlerIcon = (status: string) => {
    switch (status) {
      case ModalTypes.INFORMATION:
        return <VisibleIconBlack />;
      case ModalTypes.CANCEL_PROGRAMMING:
        return (
          <TrashIconPlus
            sx={{
              width: "24px",
              height: "24px",
            }}
          />
        );
      case ModalTypes.EDIT_PROGRAMMING:
        return <EditIconBlack />;
      case ModalTypes.SCHEDULE_CHARGING:
        return <RoadBusTransitIcon />;
      case ModalTypes.DOWNLOAD_PROOF:
        return (
          <GetAppIcon
            sx={{
              color: theme.palette.shadesOfDark.dark,
              width: "22px",
              height: "22px",
            }}
          />
        );
      default:
        return <AddIcon style={{ width: "16px" }} />;
    }
  };

  const handleButtonMenuClick = (optionTitle: ModalTypes) => {
    handleCloseMenuModal();

    switch (optionTitle) {
      case ModalTypes.INFORMATION:
        handleOpenInformationModal();
        break;
      case ModalTypes.SCHEDULE_CHARGING:
        history.push(ROUTES.USER_ROUTES.NEW_PROGRAMMING);
        break;
      case ModalTypes.EDIT_PROGRAMMING:
        history.push(ROUTES.USER_ROUTES.NEW_PROGRAMMING);
        break;
      case ModalTypes.CANCEL_PROGRAMMING:
        setOpenCancelProgrammingModal(true);
        break;
      case ModalTypes.DOWNLOAD_PROOF:
        if (rowSelected?.Ref && rowSelected?.scheduleGroupID) {
          handleRoadLoadMethod.fetchDownloadPDF(
            rowSelected.Ref,
            rowSelected.scheduleGroupID,
            rowSelected.index
          );
        }
        break;
      default:
        break;
    }
  };

  const memoizedRows = useMemo(() => {
    return (rows as IScheduledLoad[])?.map((row, indexRow) => {
      return (
        <Grid
          item
          xs={12}
          key={indexRow}
          onClick={() => handleOpenMenu(row, indexRow)}
          sx={{
            border: "0px solid white",
            cursor: "pointer",
            backgroundColor: "#fff",
            borderRadius: "8px",
            py: 1.5,
            px: 0.8,
          }}
        >
          <Grid
            container
            sx={{
              border: "0px solid blue",
              alignItems: "center",
              justifyContent: "space-around",
              gap: 1.5,
            }}
          >
            <Grid item>
              <TableHeadTypography>Agend.</TableHeadTypography>
              <TableBodyTypography>{row?.Ref}</TableBodyTypography>
            </Grid>

            <Grid item sx={{ whiteSpace: "nowrap" }}>
              <TableHeadTypography>Data Agend.</TableHeadTypography>
              <TableBodyTypography>
                {formatDateAndHourPTBR(row?.scheduleDate, row?.timezone)}
              </TableBodyTypography>
            </Grid>

            {shouldRenderCancelScheduling(status) && (
              <Grid
                item
                onClick={(event) => event.stopPropagation()}
                sx={{
                  mb: 1,
                  borderRadius: 2,
                }}
              >
                <ModalCancelScheduling
                  isDisabled={row.salesOrder?.some(
                    (order) => order.status === STATUSTYPE.FATURADO
                  )}
                  scheduleID={row.Ref ?? ""}
                  handleCancelScheduling={() =>
                    handleRoadLoadMethod.fetchCancelScheduling(
                      row.scheduleGroupID ?? ""
                    )
                  }
                />
              </Grid>
            )}

            {!featureFlagProgrammingTransport && (
              <Grid item sx={{ borderRadius: 1 }}>
                {contextLoading?.pdfReport?.[indexRow] ? (
                  <CircularProgress />
                ) : hiddenPdfReport ? (
                  <></>
                ) : (
                  <GetAppIcon
                    className={"pdf-report"}
                    color="primary"
                    sx={{
                      height: "2rem",
                      width: "2rem",
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      if (row.Ref && row.scheduleGroupID) {
                        handleRoadLoadMethod.fetchDownloadPDF(
                          row.Ref,
                          row.scheduleGroupID,
                          indexRow
                        );
                      }
                    }}
                  />
                )}
              </Grid>
            )}

            <Grid
              item
              style={{
                transform:
                  row === rowSelected ? "rotate(0deg)" : "rotate(270deg)",
                width: "12px",
              }}
            >
              <ArrowDownIconPlus
                className={"arrowMoreOption"}
                sx={{ width: "1.6rem", height: "1.6rem" }}
              />
            </Grid>
          </Grid>
        </Grid>
      );
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  useEffect(() => {
    if (!rows) return;
    if (!rowSelected) return;

    const updateIndex = (rows as IScheduledLoad[])?.findIndex(
      (row) => row?.Ref === rowSelected?.Ref
    );

    if (updateIndex !== -1)
      setRowSelected({
        ...rows[updateIndex],
        index: updateIndex,
      } as IScheduledLoad);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  return rows?.length === 0 ? (
    <TableEmpty status={props.status} />
  ) : (
    <Grid container gap={3}>
      {memoizedRows}

      {/* NOTE: Lista do Menu */}
      {isScheduled && openMenuList && (
        <FloatingMenuList
          handleClose={handleCloseMenuModal}
          title={`Agendamento ${rowSelected?.Ref}`}
          subtitle={`Data Agendada
            ${formatDateAndHourPTBR(
              rowSelected?.scheduleDate,
              rowSelected?.timezone
            )}`}
          children={
            <>
              <Grid item xs={12} textAlign={"center"}>
                <Grid container gap={2} justifyContent={"center"}>
                  {isCanceled && (
                    <Grid item>
                      <TableHeadTypography>
                        Motivo Cancelamento: {rowSelected?.reasonCancellation}
                      </TableHeadTypography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {/* NOTE: MENU */}
              <Grid container>
                {buttonData.map((button, index) => (
                  <Grid item xs={12} m={"0.5rem"}>
                    <IconButton
                      key={index}
                      onClick={() => handleButtonMenuClick(button.modalTitle)}
                      sx={{ gap: 2 }}
                    >
                      {handlerIcon(button.modalTitle)}
                      <TypographySubtitle>{button.label}</TypographySubtitle>
                    </IconButton>
                  </Grid>
                ))}
              </Grid>
            </>
          }
        />
      )}

      {/* NOTE: Modal - Informações do Agendamento*/}
      <Modal
        open={openInformation}
        hiddenButton={true}
        onClose={handleCloseInformationModal}
      >
        <Grid container gap={2} textAlign={"left"}>
          <Grid item xs={12}>
            <TypographyTitle>
              Carregamento rodoviário {isFinished ? "finalizado" : "agendado"}{" "}
            </TypographyTitle>
          </Grid>

          <Grid item xs={12} textAlign={"right"}>
            <Grid
              container
              gap={2}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{ border: "0px solid red", flexWrap: "nowrap" }}
            >
              <Grid item xs={1} sx={{ width: "20px" }}>
                {" "}
                <RoadLoadRedFullIcon />{" "}
              </Grid>

              <Grid item>
                <TableHeadTypography>Agendamento</TableHeadTypography>
                <TableBodyTypography textAlign={"center"}>
                  {rowSelected?.Ref}
                </TableBodyTypography>
              </Grid>

              <Grid item>
                <TableHeadTypography>Data Agend.</TableHeadTypography>
                <TableBodyTypography
                  style={{ color: theme.palette.success.main }}
                >
                  {formatDateAndHourPTBR(
                    rowSelected?.scheduleDate,
                    rowSelected?.timezone
                  )}
                </TableBodyTypography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} textAlign={"center"}>
            <Grid container gap={2} justifyContent={"center"}>
              <Grid item>
                <TableHeadTypography>Quant. Total em L.</TableHeadTypography>
                <TableBodyTypography>
                  {formatToAmountLiters(rowSelected?.totalAmountLiters)}
                </TableBodyTypography>
              </Grid>

              {isCanceled && (
                <Grid item>
                  <TableHeadTypography>Motivo Cancelam.</TableHeadTypography>
                  <TableBodyTypography>
                    {rowSelected?.reasonCancellation}
                  </TableBodyTypography>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TypographySubtitle>
              Ordem de Venda (<b> {rowSelected?.salesOrder?.length} </b>)
            </TypographySubtitle>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: isFinished || isCanceled ? "none" : "flex",
              gap: 2,
            }}
          >
            <Modal
              onClose={() => updateRoadSelected(undefined)}
              ModalButton={
                <IconButton
                  onClick={() => {
                    handleRoadLoadMethod.resetRoadCreated();
                  }}
                  sx={{
                    gap: 1,
                  }}
                >
                  <AddIcon style={{ width: "16px" }} />
                  <TypographySubtitle>Adicionar</TypographySubtitle>
                </IconButton>
              }
            >
              {({ handleClose }) => {
                return (
                  <Box>
                    <TypographyTitle sx={{ pb: 2 }}>
                      Adicionar Carregamento Rodoviario
                    </TypographyTitle>

                    <TypographyCaption>
                      <b style={{ color: "#000" }}>{roadsSelected.length}</b>{" "}
                      Carregamento(s) selecionado(s)
                    </TypographyCaption>

                    {tableUnscheduled && (
                      <TableSalesOrderMobile
                        hiddenScheduleButton={true}
                        multiple={true}
                        amountSalesOrder={rowSelected?.salesOrder?.length}
                        status={tableUnscheduled.status}
                        headers={tableUnscheduled.headers}
                        rows={tableUnscheduled.rows}
                        loading={tableUnscheduled.loading}
                        rowsCount={tableUnscheduled.rowsCount}
                        handleFetchRoadLoad={
                          tableUnscheduled.handleFetchRoadLoad
                        }
                      />
                    )}

                    <Box
                      style={{
                        position: "sticky",
                        bottom: "0%",
                        right: "10%",
                        left: "10%",
                        zIndex: 1,
                        display: "grid",
                        gap: 16,
                        background: "#fff",
                      }}
                    >
                      <Button
                        variant={"contained"}
                        disabled={roadsSelected.length === 0}
                        loading={loadAddOrder}
                        onClick={async () => {
                          await handleAddOrder();
                          handleClose();
                        }}
                        fullWidth
                      >
                        Adicionar
                      </Button>
                      <Button
                        variant={"outlined"}
                        onClick={() => {
                          handleClose();
                        }}
                        fullWidth
                      >
                        Cancelar
                      </Button>
                    </Box>
                  </Box>
                );
              }}
            </Modal>

            <ModalSchedule
              key={rowSelected?.Ref}
              roadLoadScheduled={(rowSelected as IScheduledLoad) ?? undefined}
              isDisable={!rowSelected?.reschedulable}
            >
              Reagendar
            </ModalSchedule>
            {hasProgramming && (
              <Button onClick={() => setOpenCancelProgrammingModal(true)}>
                <TrashIconPlus />
                Cancelar
              </Button>
            )}
          </Grid>

          <Grid item xs={12}>
            <Grid container gap={2}>
              {rowSelected?.salesOrder?.map((order, orderIndex) => {
                return (
                  <Grid item key={orderIndex} xs={12}>
                    <Grid
                      container
                      sx={{
                        background: "#f7f7f7",
                        borderRadius: 2,
                        gap: 1,
                        p: 1,
                      }}
                    >
                      <Grid item xs={12}>
                        <TableHeadTypography> Cliente </TableHeadTypography>
                        <TableBodyTypography>
                          {order?.customerID} - {order?.customer}
                        </TableBodyTypography>
                      </Grid>
                      {containsProgramming && (
                        <Grid item xs={12}>
                          <TableHeadTypography> Motorista </TableHeadTypography>
                          <TableBodyTypography>
                            {rowSelected?.truckDriver || ""}
                          </TableBodyTypography>
                        </Grid>
                      )}
                      {containsProgramming && (
                        <Grid item xs={12}>
                          <TableHeadTypography> Placa </TableHeadTypography>
                          <TableBodyTypography>
                            {rowSelected?.plate || ""}
                          </TableBodyTypography>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <TableHeadTypography> CNPJ </TableHeadTypography>
                        <TableBodyTypography>
                          {maskCNPJ(order?.cnpj)}
                        </TableBodyTypography>
                      </Grid>
                      <Grid item xs={12}>
                        <TableHeadTypography> Produto </TableHeadTypography>
                        <TableBodyTypography>
                          {order?.product}
                        </TableBodyTypography>
                      </Grid>
                      <Grid item xs={12}>
                        <TableHeadTypography> Quantidade </TableHeadTypography>
                        <TableBodyTypography>
                          {formatToAmountLiters(order?.quantity)}
                        </TableBodyTypography>
                      </Grid>
                      <Grid item xs={12}>
                        <TableHeadTypography>
                          Ordem de Venda
                        </TableHeadTypography>
                        <TableBodyTypography>
                          {order?.order}
                        </TableBodyTypography>
                      </Grid>
                      {containsProgramming && (
                        <Grid item xs={12}>
                          <TableHeadTypography>
                            Compartimentos
                          </TableHeadTypography>
                          <TableBodyTypography>
                            {rowSelected?.Transporte?.filter(
                              (transport) =>
                                transport.Ordem_de_venda === order.order
                            )
                              ?.map((transport) => transport.Numero)
                              ?.join(" - ") || "N/A"}
                          </TableBodyTypography>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <TableHeadTypography>
                          Status da Ordem de Venda{" "}
                        </TableHeadTypography>
                        <TableBodyTypography
                          sx={{ color: applyColor(order?.status, theme) }}
                        >
                          {order?.status}
                        </TableBodyTypography>
                      </Grid>
                      {isFinished || isCanceled ? (
                        <></>
                      ) : (
                        <Grid item>
                          <ModalDeleteOrder
                            disabled={
                              (rowSelected?.salesOrder?.length ?? 0) <= 1
                            }
                            onClick={async () =>
                              await handleDeleteOrder(
                                rowSelected?.index,
                                orderIndex
                              )
                            }
                            label={"Remover"}
                            order={order.order}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Modal>

      {/* NOTE: Modal de Cancelar Programação*/}
      {isScheduled && (
        <ModalCancelProgramming
          open={openCancelProgrammingModal}
          onClose={() => setOpenCancelProgrammingModal(false)}
          refSchedule={rowSelected?.Ref || "N/A"}
          handleCancelProgramming={handleCloseCancelProgrammingModal}
        />
      )}

      {roadCreated && (
        <ModalCreatedOrder
          hasBeenEdit={roadCreated.hasBeenEdit}
          open={roadCreated?.active}
          order={roadCreated?.ref}
          driverName={roadCreated?.handlerMetadata?.driverName}
          scheduleDateTime={roadCreated?.scheduleResolvedDateTime}
          timezone={roadCreated.scheduleTimezone}
          onClick={handleRoadLoadMethod.resetRoadCreated}
        />
      )}
    </Grid>
  );
};

export default TableSalesOrderGroupedMobile;
