import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { IProductBlockDTO } from "@modules/productBlock/dtos/IProductBlockDTO";
import { IProductBlockService } from "@modules/productBlock/models/IProductBlockService";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import { Branch, ProductBlockList } from "./ListProductBlock";
import ProductBlockModal from "./shared/ProductBlockModal";
import { initiaSegments, listActiveSegmentsIds } from "./ListSegmentsBlock";
import { ISegmentsDTO } from "@modules/productBlock/dtos/ISegmentsDTO";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    TitleBlock: {
      color: "#3E3D3D",
      fontWeight: "bold",
      fontFamily: "Montserrat",
      fontSize: "3rem",
      paddingBottom: "20px",
      textAlign: "left",
      margin: "0 0 0 5rem",
    },
    TableContainer: {
      width: "90%",
      margin: "0 5rem",
      boxShadow: "none",
    },
    Table: {
      borderSpacing: "0px 20px",
      borderCollapse: "separate",
      marginBottom: 16,
    },
    TableRow: {
      background: "#fff",
    },
    TableCellHead: {
      fontWeight: "bold",
      width: "13rem",
      borderBottom: "none",
    },
    TableCellFromData: {
      background: "#fff",
      marginBottom: "10px",
      borderBottom: "none",
      width: "13rem",
      fontWeight: "bold",
    },
    LastTableCell: {
      width: "5rem",
      background: "#fff",
      borderBottom: "none",
    },
  })
);

const ProductBlock: React.FC = () => {
  const listBranch = Branch;
  const listProducts = ProductBlockList;
  const classes = useStyles();
  const iocContext = useIoCContext();
  const { enqueueSnackbar } = useSnackbar();

  const [isChecked, setIsChecked] = useState<boolean[][]>(
    listBranch.map(() => new Array(listProducts.length).fill(false))
  );

  const [productIds, setProductIds] = useState({});
  const [segmentsIds, setSegmentsIds] = useState({});
  const [segmentsAll, setSegmentsAll] = useState<ISegmentsDTO[]>([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProductInfo, setSelectedProductInfo] = useState({
    branch: "",
    product: "",
    segmentsSelected: [""],
    segmentsAll: initiaSegments,
    branchIndex: -1,
    productIndex: -1,
    isBlocked: false,
  });

  function updateSelectedValidsSegments(
    segmentsList: ISegmentsDTO[]
  ): ISegmentsDTO[] {
    const validSegments: ISegmentsDTO[] = [];
    listActiveSegmentsIds.forEach((elementId) => {
      const segment = segmentsList.find((seg) => seg.id === elementId);
      if (segment) {
        validSegments.push(segment);
      }
    });
    return validSegments;
  }

  const handleCheckboxClick = (
    branch: string,
    productName: string,
    branchIndex: number,
    segmentsNames: string[],
    productIndex: number
  ) => {
    const isProductBlocked = isChecked[branchIndex][productIndex];
    const currentAllowed = segmentsNames ?? [];
    const differenceSegments = isProductBlocked
      ? listActiveSegmentsIds.filter(
          (segmentId) => !currentAllowed.includes(segmentId)
        )
      : [];
    const checkedSegments = differenceSegments;
    const validSegmentsObject =
      segmentsAll.length > 0 && updateSelectedValidsSegments(segmentsAll);

    setSelectedProductInfo({
      branch,
      product: productName,
      segmentsSelected: checkedSegments,
      segmentsAll: validSegmentsObject || initiaSegments,
      isBlocked: isProductBlocked,
      branchIndex,
      productIndex,
    });
    setIsModalOpen(true);
  };

  const productBlockService = iocContext.serviceContainer.get<
    IProductBlockService
  >(Types.ProductBlock.IProductBlockService);

  const getRegisteredProducts = useCallback(async () => {
    const updatedCheck = listBranch.map(() =>
      new Array(listProducts.length).fill(false)
    );
    const newProductIds: Record<string, string> = {};
    const newSegments: Record<string, string[]> = {};

    try {
      const response = await productBlockService.getProduct();
      response.forEach((valueProducts: IProductBlockDTO) => {
        const { center, materialId, id, allowedSegments } = valueProducts;
        listBranch.forEach((branch, branchIndex) => {
          listProducts.forEach((product, productIndex) => {
            if (
              branch.code.includes(center) &&
              product.code.includes(materialId)
            ) {
              const isProductBlocked =
                !allowedSegments ||
                allowedSegments.length === 0 ||
                allowedSegments.length !== listActiveSegmentsIds.length;

              updatedCheck[branchIndex][productIndex] = isProductBlocked;
              newProductIds[`${branchIndex}-${productIndex}`] = id || "";
              newSegments[`${branchIndex}-${productIndex}`] =
                allowedSegments || [];
            }
          });
        });
      });

      setIsChecked(updatedCheck);
      setProductIds(newProductIds);
      setSegmentsIds(newSegments);
    } catch {
      enqueueSnackbar("Não foi possível realizar a operação", {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, listBranch, listProducts, productBlockService]);

  const getSegmentsAll = useCallback(async () => {
    try {
      const response = await productBlockService.getSegments();
      setSegmentsAll(response);
    } catch {
      enqueueSnackbar("Não foi possível carregar os segmentos", {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, productBlockService]);

  const getCheckboxId = (branchIndex: number, productIndex: number) =>
    `${branchIndex}-${productIndex}`;

  const handleConfirm = async (
    branchIndex: number,
    productIndex: number,
    isBlocked: boolean,
    checkedSegments: Record<string, boolean>
  ) => {
    const filterFalseSegments = Object.keys(checkedSegments).filter(
      (key) => !checkedSegments[key]
    );
    const productId = productIds[getCheckboxId(branchIndex, productIndex)];
    const sendProduct: IProductBlockDTO = {
      center: listBranch[branchIndex].code,
      materialId: listProducts[productIndex].code,
      allowedSegments: filterFalseSegments,
    };

    try {
      if (isBlocked) {
        await productBlockService.deleteProduct(
          productId,
          filterFalseSegments.length ? filterFalseSegments : undefined
        );
        enqueueSnackbar("Produto desbloqueado com sucesso!", {
          variant: "success",
        });
      } else {
        await productBlockService.execute(sendProduct);
        enqueueSnackbar("Produto bloqueado com sucesso!", {
          variant: "success",
        });
      }
      const updatedCheck = isChecked.map((row, i) =>
        row.map((checked, j) =>
          i === branchIndex && j === productIndex ? !isBlocked : checked
        )
      );
      setIsChecked(updatedCheck);
      getRegisteredProducts();
    } catch (error) {
      enqueueSnackbar("Erro ao realizar a operação", { variant: "error" });
      console.error(error);
    } finally {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    getRegisteredProducts();
  }, [getRegisteredProducts]);

  useEffect(() => {
    getSegmentsAll();
  }, [getSegmentsAll]);

  return (
    <Layout disableToolbar={true} disableToolbarMobile={true}>
      <Typography className={classes.TitleBlock}>
        Bloqueio de produtos
      </Typography>

      <ProductBlockModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        productInfo={selectedProductInfo}
        onConfirm={handleConfirm}
      />

      <TableContainer className={classes.TableContainer}>
        <Table className={classes.Table}>
          <TableHead>
            <TableRow className={classes.TableRow}>
              <TableCell style={{ borderBottom: "none" }} />
              {listProducts.map((title) => (
                <TableCell
                  key={title.code}
                  align="center"
                  className={classes.TableCellHead}
                >
                  {title.name}
                  <br />
                  <span style={{ fontWeight: "normal" }}>{title.code}</span>
                </TableCell>
              ))}
              <TableCell className={classes.LastTableCell} />
            </TableRow>
          </TableHead>

          <TableBody>
            {listBranch.map((branch, branchIndex) => (
              <TableRow key={branch.code} className={classes.TableRow}>
                <TableCell align="left" className={classes.TableCellFromData}>
                  {branch.name}
                  <br />
                  <span style={{ fontWeight: "normal" }}>{branch.code}</span>
                </TableCell>
                {listProducts.map((product, productIndex) => {
                  const productId =
                    productIds[getCheckboxId(branchIndex, productIndex)] || "";
                  const segmentsNames =
                    segmentsIds[getCheckboxId(branchIndex, productIndex)] || [];

                  return (
                    <TableCell
                      key={product.code}
                      className={classes.TableCellFromData}
                      align="center"
                    >
                      <Checkbox
                        style={{ color: "#D91F05" }}
                        color="primary"
                        value={productId}
                        onChange={() =>
                          handleCheckboxClick(
                            branch.code,
                            product.name,
                            branchIndex,
                            segmentsNames,
                            productIndex
                          )
                        }
                        checked={isChecked[branchIndex][productIndex]}
                      />
                    </TableCell>
                  );
                })}
                <TableCell className={classes.LastTableCell} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Layout>
  );
};

export default ProductBlock;
